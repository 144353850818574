
/* ----------------------------------------
    Imports
---------------------------------------- */

import { Component, Vue } from 'vue-property-decorator';
import { mapState, mapMutations } from 'vuex';
import { AppStore } from '@interface/store/App';
import { handle429 } from '@util/util.ts';
import { app } from '@config';
import CardDefault from '@part/elements/CardDefault.vue';
import CardFull from '@part/elements/CardFull.vue';
import InputField from '@part/elements/InputField.vue';
import Pearls from '@part/elements/Pearls.vue';
import Overlay from '@part/layout/Overlay.vue';
import FilterMenu from '@part/elements/FilterMenu.vue';
import FiltersWhiteSvg from '@svg/FiltersWhite.vue';
import SurpriseSvg from '@svg/Surprise.vue';
import axios from 'axios';
import ArrowOpenSvg from '@svg/ArrowOpen.vue';
import Dropdown from '@part/elements/Dropdown.vue';
import Thumb from '@part/elements/Thumb.vue';

/* -------------  ---------------------------
    Component
---------------------------------------- */

@Component({
  components: {
    Dropdown,
    CardDefault,
    CardFull,
    InputField,
    FilterMenu,
    Thumb,
    Overlay,
    Pearls,
    FiltersWhiteSvg,
    SurpriseSvg,
    ArrowOpenSvg
  },
  props: {
    setOverlayStatus: {
      type: Function,
      required: false
    }
  },
  data() {
    return {
      animating: true,
      filtered: false,
      overlayVisible: false,
      loadingEventData: true,
      loadingMuseumData: true,
      filterData: {},
      eventData: {},
      museumData: {},
      pearls: {},
      visibleEvents: 0,
      adverts: [],
      pageData: null,
      isFiltering: false,
      filtersHeight: 0,
      activeTab: 'events'
    };
  },
  computed: {
    ...mapState(['selectedFilters', 'filteredEvents', 'filteredMuseums', 'selectedAmbassador'])
  },
  methods: {
    ...mapMutations([
      'selectAmbassadorFilter',
      'addToFilters',
      'resetFilters',
      'resetAmbassadorFilters',
      'filterEvents',
      'filterEventArr',
      'setUnfilteredEvents',
      'setUnfilteredMuseums',
      'setFilteredMuseums'
    ]),

    handleTabClick(tab) {
      this.activeTab = tab;
    },
    loadMore() {
      this.visibleEvents += 10;
      // rounds number so that it adds either 3 or 5 items, depending on the grid
      let roundTo = this.filtered ? 3 : 5;
      this.visibleEvents = Math.round(this.visibleEvents / roundTo) * roundTo;
    },
    onSearch() {
      this.filtered = true;
    },
    filtering(filtering) {
      this.isFiltering = filtering;
      this.loadingEventData = filtering;
      this.loadingMuseumData = filtering;
    },
    toggleFilters() {
      if (this.filtersHeight === 0) {
        this.filtersHeight = 700;
        return;
      }
      this.filtersHeight = 0;
    }
  },
  mounted() {
    this.loadMore();

    // ///// QUICKFIX /////////
    document.title = 'Programma | Museumnacht Amsterdam';
    // /////////////////////////

    // request for event data
    axios
      .get(`${app.api.api}/feed/events`)
      .then(response => {
        this.eventData = response.data;
        this.filterEvents(response.data);
        this.setUnfilteredEvents(response.data);
        this.loadingEventData = false;
        setTimeout(() => {
          this.animating = false;
        }, 500);
      })
      .catch(error => {
        console.log(`🔥${error}🔥`);
        handle429(this, error);
      });

    axios
      .get(`${app.api.api}/page/${this.$route.meta.id}`)
      .then(response => {
        this.pageData = response.data;
      })
      .catch(error => {
        console.log(`🔥${error}🔥`);
        handle429(this, error);
      });

    axios
      .get(`${app.api.api}/feed/museums`)
      .then(response => {
        this.museumData = response.data;
        this.setFilteredMuseums(response.data);
        this.setUnfilteredMuseums(response.data);
        this.loadingMuseumData = false;
      })
      .catch(error => {
        console.log(`🔥${error}🔥`);
        handle429(this, error);
      });
  }
})

/* ----------------------------------------
    Export
---------------------------------------- */
export default class Search extends Vue {}
